import React, { useEffect } from "react";
import UserDetail from "./UserDetail";
import Loader from "./Loader";
import Update from "./Update";

const Home = () => {
  return (
    <>
      {/* <Loader />   */}
      {/* <Update /> */}
      <div className="vbgmov_bg">
        <div className="vklogo_pos">
          <img src="/assets/images/img-1.jpg" className="vba_banner" alt="" />
          <img
            src="/assets/images/img-2.jpg"
            className="vba_banner_desk"
            alt=""
          />
          <img src="/assets/images/vklogo.jpg" className="vk_logo" alt="" />
        </div>
        <div className="desk_view">
          <div className="container">
            <div className="vba_text">
              <h2 className="than_head">Thank You</h2>
              <h4 className="play_head">For playing a vital role in the</h4>
              <h1 className="vba_head">
                Viksit Bharat <span>Ambassador</span>
              </h1>
              <h4 className="play_head">movement!</h4>
            </div>
          </div>

          <UserDetail />
        </div>
      </div>
    </>
  );
};

export default Home;
