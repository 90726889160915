import React, { useState } from "react";
import axios from "axios";

const UserDetail = () => {
  const [formData, setFormData] = useState({
    name: "",
    profession: "",
    email: "",
    mobileno: "",
    state: "",
  });

  const [nameError, setNameError] = useState("");
  const [professionError, setProfessionError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [mobilenoError, setMobilenoError] = useState("");
  const [stateError, setStateError] = useState("");
  const [submit, setSubmit] = useState(false);

  const professions = [
    "Student",
    "Professional",
    "Business/Self Employed",
    "Home maker",
    "Academics",
    "NGO sector",
  ];

  const states = [
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal",
  ];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    console.log("Hellosssssss");
    if (name === "mobileno") {
      console.log("Hello");
      if (/^\d*$/.test(value) && value.length <= 10) {
        console.log("H@");
        setFormData({ ...formData, mobileno: value });
        if (value.length === 10) {
          setMobilenoError("");
          console.log("h3");
        } else {
          setMobilenoError("Number must be exactly 10 digits.");
        }
      } else {
        setMobilenoError("Invalid number format.");
      }
    }
    if (name === "name" && value) setNameError("");
    if (name === "profession" && value) setProfessionError("");
    if (name === "email" && value) {
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (emailRegex.test(value)) {
        setEmailError("");
      } else {
        setEmailError("Email format is incorrect.");
      }
    }
    if (name === "state" && value) setStateError("");
  };

  const validateForm = () => {
    let isValid = true;
    if (!formData.name) {
      setNameError("Please enter your Name to proceed.");
      isValid = false;
    }
    if (!formData.profession) {
      setProfessionError("Please select Profession to proceed.");
      isValid = false;
    }
    if (!formData.state) {
      setStateError("Please select State to proceed.");
      isValid = false;
    }
    if (!formData.mobileno) {
      setMobilenoError("Please enter a valid 10 digit mobile number.");
      isValid = false;
    } else if (formData.mobileno.length !== 10) {
      setMobilenoError("Number must be exactly 10 digits.");
      isValid = false;
    } else {
      setMobilenoError("");
    }
    if (!formData.email) {
      setEmailError("Please enter a valid email ID.");
      isValid = false;
    } else {
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (!emailRegex.test(formData.email)) {
        setEmailError("Email format is incorrect.");
        isValid = false;
      }
    }
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      const { name, profession, email, mobileno, state } = formData;

      try {
        const response = await axios.post(
          "https://campaign-api.narendramodi.in/all-campaign-data-2024",
          {
            action: "save-new",
            data: {
              name,
              profession,
              email,
              mobileno,
              address: state,
            },
          }
        );

        window.gtag("event", "submit_success", {
          event_category: "form",
          event_label: "Submit Successful",
          value: 1,
        });

        console.log("Form submitted successfully:", response.data);
        setSubmit(true);
        setFormData({
          name: "",
          profession: "",
          email: "",
          mobileno: "",
          state: "",
        });
        setTimeout(() => {
          setSubmit(false);
        }, 3000);
      } catch (error) {
        console.error("Error submitting the form:", error);
      }
    }
  };

  return (
    <div className="userdetail_bg">
      <div className="container">
        <div className="user_detail_content">
          <p>
            Please provide your details to receive a letter of appreciation
            recognizing your efforts as a proud{" "}
            <span>Viksit Bharat Ambassador!</span>
          </p>

          <form onSubmit={handleSubmit}>
            <div className="user_form">
              <h5>Name</h5>
              <input
                type="text"
                name="name"
                placeholder="Your name"
                className="name_input"
                value={formData.name}
                onChange={handleInputChange}
              />
              {nameError && (
                <p
                  style={{
                    color: "red",
                    textAlign: "left",
                    fontWeight: "bold",
                  }}
                >
                  {nameError}
                </p>
              )}
            </div>
            <div className="user_form">
              <h5>WhatsApp Number</h5>
              <input
                type="tel"
                name="mobileno"
                placeholder="Enter number"
                className="name_input"
                value={formData.mobileno}
                onChange={handleInputChange}
                maxLength="10"
              />
              {mobilenoError && (
                <p
                  style={{
                    color: "red",
                    textAlign: "left",
                    fontWeight: "bold",
                  }}
                >
                  {mobilenoError}
                </p>
              )}
            </div>
            <div className="user_form">
              <h5>Email ID</h5>
              <input
                type="email"
                name="email"
                placeholder="Enter Email ID"
                className="name_input"
                value={formData.email}
                onChange={handleInputChange}
              />
              {emailError && (
                <p
                  style={{
                    color: "red",
                    textAlign: "left",
                    fontWeight: "bold",
                  }}
                >
                  {emailError}
                </p>
              )}
            </div>
            <div className="user_form">
              <h5>Profession</h5>
              <div className="select_div">
                <select
                  name="profession"
                  value={formData.profession}
                  onChange={handleInputChange}
                >
                  <option value="">Select</option>
                  {professions.map((profession) => (
                    <option key={profession} value={profession}>
                      {profession}
                    </option>
                  ))}
                </select>
                <img src="/assets/images/arrowdown.svg" alt="" />
              </div>
              {professionError && (
                <p
                  style={{
                    color: "red",
                    textAlign: "left",
                    fontWeight: "bold",
                  }}
                >
                  {professionError}
                </p>
              )}
            </div>
            <div className="user_form">
              <h5>State</h5>
              <div className="select_div">
                <select
                  name="state"
                  value={formData.state}
                  onChange={handleInputChange}
                >
                  <option value="">Select</option>
                  {states.map((state) => (
                    <option key={state} value={state}>
                      {state}
                    </option>
                  ))}
                </select>
                <img src="/assets/images/arrowdown.svg" alt="" />
              </div>
              {stateError && (
                <p
                  style={{
                    color: "red",
                    textAlign: "left",
                    fontWeight: "bold",
                  }}
                >
                  {stateError}
                </p>
              )}
            </div>
            <button type="submit" className="submit_btn">
              SUBMIT
            </button>
            {submit && (
              <p style={{ color: "green", fontWeight: "bold" }}>
                Form Submitted Successfully
              </p>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default UserDetail;
