import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./Home";



const AppRouter = () => {
    return (
        <>
            <Routes>
                <Route exact path="/" element={<Home />} />

            </Routes>
        </>
    )
}

export default AppRouter;